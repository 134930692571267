import React from 'react'
import "./Footer.css"
import logo from "../assets/logo-csc.png"



export default function Footer() {
  return (
    <>
    <footer className='footer'>
      <div className='footer-logo'>
        <img src={logo} alt='Consultora Salud Corporativa'/>
      </div>
      <div className='footer-content'>
        <span>Dirección</span>
        <p>Jr. Cosmos 144, Santiago de Surco</p>
        <span>Teléfono</span>
        <p className='foo-tel'>+51 930509438</p>
        <span>Correo</span>
        <p>infoeades@grupoeades.org</p>
      </div>
      <span>Todos los derechos reservados 2024</span>
    </footer> 
    </>
  )
}
