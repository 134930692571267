import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./WhIcon.css"

export default function WhIcon(){

    const handleClick1 = () => {
        window.location.href="https://api.whatsapp.com/send?phone=51930509438&text=Buenas%2C%20estoy%20interesad%40%20en%20sus%20servicios%2C%20mi%20nombre%20es..."
    }

    return (
        <aside id='sticky-wh' onClick={handleClick1}>
            <FontAwesomeIcon icon={faWhatsapp} />
        </aside>
    )
}